import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const catPaths = [
  {
    path: `m176 488h-8c-75.1 0-136-78.8-136-176 0-56 56-200 56-224 0 13.3-10.7 24-24 24s-24-10.7-24-24c0-26.5 21.5-48 48-48s48 21.5 48 48c0 24-56 168-56 224 0 70.7 39.4 128 88 128z`,
    fill: "#464c4f"
  },
  {
    path: `m424 488 32-288s24-24 24-56-16-56-16-56l-8-64-40 32c-16-8-48-8-48-8s-24 0-48 8l-40-32-8 64s-16 24-16 56 24 56 24 56c-64 0-152 80-152 184s48 104 48 104z`,
    fill: "#606669"
  },
  {
    path: `m392 112h48s0 32-24 32-24-32-24-32z`,
    fill: "#f78a25"
  }, 
  {
    path: `m296 112h48s0 32-24 32-24-32-24-32z`,
    fill: "#f78a25"
  },
  {
    path: `m429.3 440-5.3 48h-96l-8-48z`,
    fill: "#757c80"
  },
  {
    path: `m24 312c0 48.8 14.7 94.7 41.5 129.3 27.2 35.3 63.6 54.7 102.5 54.7h256c4.1 0 7.5-3.1 8-7.1l31.7-285.4c6.2-7 24.3-29.9 24.3-59.5 0-29.5-12.5-52.4-16.3-58.7l-7.8-62.3c-.5-4.4-4.5-7.5-8.9-6.9-1.5.2-2.9.8-4 1.7l-36.3 29c-18-6.7-45.4-6.8-46.7-6.8-15.7.3-31.3 2.7-46.3 7.1l-36.7-29.3c-3.5-2.8-8.5-2.2-11.2 1.2-.9 1.2-1.5 2.5-1.7 4l-7.8 62.2c-3.8 6.3-16.3 29.3-16.3 58.7 0 20.8 9 38.3 16.5 49.3-30.6 5.1-63.4 24.6-90.4 54.3-34.9 38.5-54.1 86.9-54.1 136.5 0 9 .4 17.3 1 25-20.5-22.3-33-58.1-33-97 0-32.6 20.1-97.5 36.3-149.7 12.6-40.6 19.7-64.1 19.7-74.3 0-30.9-25.1-56-56-56s-56 25.1-56 56c0 17.7 14.3 32 32 32 3 0 5.9-.4 8.8-1.2-3.3 11.5-7.5 25-11.8 38.8-16.5 53.2-37 119.3-37 154.4zm305.9 136h38.1v32h-33.1zm54.1 32v-32h36.4l-3.6 32zm-198.1-221.6c28.3-31.1 64.3-50.4 94.1-50.4 4.4 0 8-3.6 8-8 0-2.1-.8-4.1-2.3-5.6-.2-.2-21.7-22.2-21.7-50.4 0-29 14.5-51.4 14.7-51.6.7-1 1.1-2.2 1.3-3.4l6.2-49.8 28.8 23c2.1 1.7 5 2.2 7.5 1.3 22.4-7.5 45.2-7.6 45.5-7.6 8.4 0 32.7 1.3 44.4 7.2 2.8 1.4 6.1 1 8.6-.9l28.8-23.1 6.2 49.8c.2 1.2.6 2.4 1.3 3.4.1.2 14.7 22.3 14.7 51.6 0 28.2-21.5 50.1-21.7 50.3-1.3 1.3-2.1 3-2.3 4.8l-25.8 233h-38.2v-152c0-4.4-3.6-8-8-8s-8 3.6-8 8v152h-40c-.2 0-.3 0-.5 0l-23.6-153.2c-.7-4.4-4.8-7.4-9.1-6.7s-7.4 4.8-6.7 9.1l22.6 146.8c-16.6-17.8-39.9-27.9-64.3-28h-30.4c-4.4 0-8 3.6-8 8s3.6 8 8 8h30.4c34.3.1 63.8 24.3 70.6 57.9l1.2 6.1h-142.2c-.1 0-8.9-.4-18.1-10.4-10-10.8-21.9-34.4-21.9-85.6 0-45.6 17.7-90.2 49.9-125.6zm-97.9-178.4c-4.4 0-8 3.6-8 8 0 .7-.1 1.4-.1 2.1-1.2 8.8-9.3 14.9-18 13.7-8-1-13.9-7.8-13.9-15.8 0-22.1 17.9-40 40-40s40 17.9 40 40c0 8.3-9.2 38.1-19 69.6-16.5 53.2-37 119.3-37 154.4 0 51.5 20.5 98.4 52.5 121.4 4.3 20.4 11.1 34.1 18.5 43.4-58.7-15.3-103-83.4-103-164.8 0-32.6 20.1-97.5 36.3-149.7 10.7-34.5 17.4-56.6 19.2-68.8.3-1.8.5-3.7.5-5.6 0-4.3-3.6-7.9-8-7.9z`,
    fill: "none"
  },
  {
    path: `m416 152c25.3 0 32-26.2 32-40 0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8 .1 6.4 1.3 12.8 3.5 18.8 5.1 13.7 15.3 21.2 28.5 21.2zm15-32c-.4 1.8-.9 3.5-1.5 5.2-3.6 9.7-9.5 10.8-13.5 10.8s-9.7-1.1-13.4-10.5c-.7-1.8-1.2-3.6-1.6-5.5z`,
    fill: "none"
  },
  {
    path: `m320 152c25.3 0 32-26.2 32-40 0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8 .1 6.4 1.3 12.8 3.5 18.8 5.1 13.7 15.3 21.2 28.5 21.2zm15-32c-.4 1.8-.9 3.5-1.5 5.2-3.6 9.7-9.5 10.8-13.5 10.8s-9.7-1.1-13.4-10.5c-.7-1.8-1.2-3.6-1.6-5.5z`,
    fill: "none"
  },
  {
    path: `m336.6 179c1.6 4.1 6.3 6.1 10.4 4.5 9.3-3.7 16.2-7.4 21-11.4 4.9 4 11.8 7.7 21 11.4 4.1 1.6 8.8-.4 10.4-4.5s-.4-8.8-4.5-10.4c-15.9-6.4-19.2-10.9-19.8-12.1-2-4-6.8-5.6-10.7-3.6-1.5.8-2.8 2-3.6 3.6-.6 1.2-3.9 5.8-19.8 12.1-4.1 1.6-6.1 6.3-4.4 10.4z`,
    fill: 'none'
  }
]


const numCats = 180;
const data = Array.from({ length: numCats }, (_, i) => ({
  catPaths: catPaths,
  id: i, 
  rotation: Math.floor(Math.random() * 360) // get random rotation between 0 and 360 degrees
}))


const CatsViz = ({ windowWidth }) => {

  //const width=450;
  const height = 450;
  const catsScale = 0.07;
  //const catsRadius = 150;

  const svgRef = useRef();
  
  useEffect(() => {

    let width;
    let catsRadius; 
    if (windowWidth >= 550) {
      width = 480;
      catsRadius = 150;
    } else {
      width = 300;
      catsRadius = 120;
    }

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)

    const catsG = svg.selectAll("g").data([0]).join("g")
      .attr("transform", `translate(${width/2}, ${height/2})`)
        
    const cats  = catsG
      .selectAll(".cat-path-g")
      .data(data)
      .join("g")
        .classed('cat-path-g', true)

    cats.selectAll('.cat-path')
      .data(catPaths)
      .join("path")
        .classed("cat-path", true)
        .attr("d", d => d.path)
        .attr("fill", d => d.fill)
        .attr("opacity", 0.8)
    
    const simulation = d3.forceSimulation(data)
        .on("tick", () => {
          cats.attr("transform", d => `scale(${catsScale})translate(${d.x}, ${d.y})`)
        })
        .force("collide", d3.forceCollide().radius(catsRadius))

  }, [windowWidth])

  return (
    <div id="cats-viz-container">
      <svg ref={svgRef}>
      </svg>
    </div>
  )
};

export default CatsViz;